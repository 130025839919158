<template>
    <span
        class="FillTextEffect"
        :class="{ 'FillTextEffect--filled-by-default': filledByDefault }"
        :id="id + '-fill-effect-text'"
    >
        <template v-if="text">
            {{ text }}
        </template>
        <slot v-else />
    </span>
</template>

<script setup lang="ts">
interface FillEffectTextProps {
    id: string
    text?: string
    triggerContainer: string
    bottomPosition?: number | string
    markers?: boolean
    filledByDefault?: boolean
}

const props = defineProps<FillEffectTextProps>()

if (!props.triggerContainer)
    console.warn(
        'No scroll trigger for ' +
            props.id +
            '. Animation may not work as expected.',
    )

onMounted(() => {
    if (props.filledByDefault) return

    const { gsap } = useGsap()

    gsap.set('#' + props.id + '-fill-effect-text', {
        backgroundSize: '0% 100%',
    })

    gsap.to('#' + props.id + '-fill-effect-text', {
        scrollTrigger: {
            trigger: props.triggerContainer,
            start: 'top center',
            end: (props.bottomPosition || '80%') + ' center',
            scrub: true,
            markers: props.markers,
        },
        backgroundSize: '100% 100%',
        ease: 'none',
    })
})
</script>

<style lang="scss" scoped>
.FillTextEffect {
    position: relative;
    display: inline;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.1);
    -webkit-background-clip: text;
    background-repeat: no-repeat;
    background-image: linear-gradient(#fff, #fff);
    background-size: 0% 100%;
    padding-right: 0.25rem;
}

.FillTextEffect--filled-by-default {
    background-size: 100% 100%;
}

.theme--white {
    .FillTextEffect {
        -webkit-text-fill-color: rgba(0, 0, 0, 0.1);
        background-image: linear-gradient(#000, #000);
    }
}
</style>
